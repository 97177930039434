<template>
  <div class="about-us contacts-page" style="margin-top: 40px">
    <div class="round-right"></div>
    <div class="round-left"></div>
    <div class="section">
      <div class="container">
        <div class="contacts-section">
          <div class="contacts-wrapper">
            <div class="contacts-title">
              Наши <span>контакты</span>
            </div>
            <div class="contacts-block box-shadow">
              <div class="d-flex justify-content-between w-75">
                <div class="contacts-phone-email">
                  <div class="contacts-phone-email-title">
                    Номер телефона
                  </div>
                  <div class="contacts-phone-email-text">
                    +7 706 708 0424
                  </div>
                </div>
                <div class="contacts-phone-email">
                  <div class="contacts-phone-email-title">
                    E-mail
                  </div>
                  <div class="contacts-phone-email-text">
                    it@ts-technology.kz
                  </div>
                </div>
              </div>
              <div class="contacts-page-requisites">
                <div class="requisites-block">
                  <div class="requisites-title">
                    Реквизиты
                  </div>
                  <div class="requisites-text">
                    ТОО "TROUBLESHOOTING TECHNOLOGY"
                  </div>
                  <div class="requisites-text">
                    БИН<span>191140015704</span>
                  </div>
                  <div class="requisites-text">
                    ИИК<span>KZ51998BTB0000517245</span>
                  </div>
                  <div class="requisites-text">
                    АО «First Heartland Jýsan Bank»
                  </div>
                  <div class="requisites-text">
                    БИН<span>TSESKZKA</span>
                  </div>
                </div>
              </div>
              <div class="contacts-page-map box-shadow">
                <div class="contacts-page-map-title">
                  Республика Казахстан, г. Алматы, пр-т Абая 68/74
                </div>
                <a href="https://maps.app.goo.gl/U7jtrtWCkBB53svZ6" target="_blank" class="contacts-page-btn">Открыть на карте</a>
              </div>
            </div>
          </div>

          <div class="email-form">
            <div class="email-form-logo"></div>
            <div class="email-form-inputs">
              <el-input placeholder="Имя" v-model="input"></el-input>
              <el-input placeholder="E-mail" v-model="input"></el-input>
            </div>
            <el-input
                class="email-form-textarea"
                type="textarea"
                :rows="10"
                placeholder="Текст сообщения"
                v-model="textarea">
            </el-input>
            <button class="email-form-btn">
              Отправить
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import Vue from "vue";

export default {
  name: 'Contacts',
  data() {
    return {
      currentTab: 'all',
    }
  },
}
</script>
<style>

</style>
